import { useEffect, useState } from "react";
import AppConfig from "../appconfig";
import { Bivak } from "../entities/Bivak";
import AccommodationItem from "../components/AccommodationItemComponent";
import FooterComponent from "../components/FooterComponent";
import { Link } from "react-router-dom";

const LandingPage = () => {
    const [topBivaks, setTopBivaks] = useState<Bivak[]>();
    
    const fetchTopBivaks = async () => {
        const response = await fetch(`${AppConfig.ApiBaseUrl}api/bivaks?pagenumber=1&pagesize=5&sortBy=likes`);

        if (response.ok)
        {
            const bivaks: Bivak[] = await response.json();
            setTopBivaks(bivaks);
        }
    }

    useEffect(() => {
        fetchTopBivaks();
    }, []);

    return (
        <div className="max-w-screen-xl mx-auto px-5">
            <div className="grid lg:grid-cols-2 place-items-center pt-16 pb-4 md:pt-12 md:pb-12">
                <div className='text-center md:text-left'>
                    <h1 className='text-5xl lg:text-6xl xl:text-7xl font-bold lg:tracking-tight xl:tracking-tighter'>Čau dobrodruhu, víš <span className='text-[#fc336c]'>Kde Spát</span> ?</h1>
                    <p className='text-lg mt-4 mb-6 md:mb-0 text-slate-600 max-w-xl'>Pokud ano, sdílej svá místa s námi! Kde Spát je platforma, kde chceme sdílet a objevovat nová místa na spaní v přírodě. Pomoz ostatním dobrodruhům najít jejich další nocleh a objevuj s námi krásy přírody. Stačí kliknout na tlačítko níže!</p>
                </div>
                <div>
                    <img src="./mainPageImage.png" alt="Spaní pod hvězdami"/>
                </div>
            </div>
            <div className="text-center mt-2">
                <Link to="/add" className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-[#fc336c] text-white hover:bg-[#fc336c] border-2 border-transparent">
                    Přidat nové místo na spaní
                </Link>
            </div>
            <div className="pb-5 mt-8">
                <p className="text-3xl font-bold mb-4">Nejoblíbenější místa na spaní</p>
                {topBivaks?.map((bivak) => <AccommodationItem key={bivak.id} bivak={bivak} />)}
            </div>
            <FooterComponent />
        </div>
    );
};

export default LandingPage;
